<template>
  <div
    class="row-notify position-right off-canvas-absolute"
    :class="[ toogleMenu ? 'is-open': 'is-close' ]"
    id="offCanvas"
    data-off-canvas
    data-transition="overlap"
    v-click-outside="vcoConfig"
  >
    <button
      class="close-button"
      aria-label="Close menu"
      type="button"
      @click="onClose"
    >
      <img :src="close" alt="Close">
    </button>
    <h2 class="heading-2 margin-bottom--20">{{ $t('notification.title') }}</h2>
    <hr>
    <p v-if="notifications.length === 0">{{ $t('notification.no-data') }}</p>
    <div
      v-for="(msg, index) in notifications"
      :key="msg.id"
    >
      <div
        class="grid-x grid-margin-x column-notify"
      >
        <div class="cell auto">
          <h2 class="heading-2">{{ msg.title }}</h2>
          <p class="datetime">{{ formatDatetime(msg.createdAt) }}</p>
          <p class="status" v-if="msg.action && msg.action.type === 'show-detail'">
            {{ $t('notification.status') }}:
            <span
              :class="[ msg.status === null ? 'red': 'green' ]"
            >
              {{ msg.status }}
            </span>
          </p>
          <p class="margin-top--0 description" v-html="msg.message"/>
        </div>
        <div class="btns-template cell shrink">
          <div class="btn-read">
            <div v-show="!msg.readedAt" class="read"/>
            <a
              class="btn-read-icon"
              data-toggle="dropdown-bottom-right"
              @click="setActions(msg.id)"
            >
              <img :src="actionIcon" alt="Read">
            </a>
            <div
              class="dropdown-pane drop"
              data-position="bottom"
              data-alignment="right"
              id="dropdown-bottom-right"
              data-dropdown
              data-auto-focus="true"
              v-show="actionSelected === msg.id"
            >
              <ul class="menu">
                <li @click="readNotification(index, msg)">
                  {{ $t('notification.mark-as-read') }}
                </li>
              </ul>
            </div>
          </div>
          <div class="btn-action" v-if="msg.action && msg.action.type === 'show-detail'">
            <a @click="getDetails(index, msg)">
              {{ $t('notification.see-more') }}
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import gql from 'graphql-tag';
import { mapGetters, mapMutations } from 'vuex';
import vClickOutside from 'v-click-outside';
import actionIcon from '@/assets/images/action-icon.svg';
import close from '@/assets/images/close.svg';
import listNotificationsGql from '@/graphql/queries/listNotifications.gql';
import notificationsGql from '@/graphql/suscriptions/newNotification.gql';
import ReadNotificationGql from '@/graphql/mutations/readNotification.gql';

export default {
  directives: {
    clickOutside: vClickOutside.directive,
  },
  data() {
    return {
      actionIcon,
      close,
      actionSelected: null,
      listNotifications: [],
      vcoConfig: {
        handler: this.onClose,
        middleware: this.middleware,
        events: ['click'],
      },
      forceClose: false,
    };
  },
  apollo: {
    listNotifications: {
      query: gql`${listNotificationsGql}`,
      subscribeToMore: {
        document: gql`${notificationsGql}`,
        updateQuery: (previousResult, { subscriptionData }) => {
          const { notificationAdded } = subscriptionData.data;
          const currentItem = previousResult
            .listNotifications
            .findIndex(({ id }) => id === notificationAdded.id);

          if (currentItem > -1) {
            previousResult.listNotifications.splice(currentItem, 1, notificationAdded);
            return previousResult;
          }

          return {
            listNotifications: [
              ...previousResult.listNotifications,
              notificationAdded,
            ],
          };
        },
      },
    },
  },
  mounted() {
    this.toogleMenu = false;
  },
  computed: {
    ...mapGetters({
      notifications: 'notification/getNotifications',
    }),
    toogleMenu: {
      get() {
        return this.$store.state.notification.toogleMenu;
      },
      set(value) {
        this.setToogleMenu(value);
      },
    },
  },
  methods: {
    ...mapMutations({
      setToogleMenu: 'notification/setToogleMenu',
      setNotifications: 'notification/setNotifications',
      setDetails: 'notification/setDetails',
      markAsRead: 'notification/markAsRead',
    }),
    formatDatetime(value) {
      if (!value) return '';
      return this.$moment(value)
        .format('hh:mm A DD/MM/YY');
    },
    setActions(id) {
      if (id === this.actionSelected) {
        this.actionSelected = null;
      } else {
        this.actionSelected = id;
      }
    },
    async readNotification(index, item) {
      this.actionSelected = null;
      if (!item.readedAt) {
        await this.$apollo.mutate({
          mutation: ReadNotificationGql,
          variables: { id: item.id },
        });
        this.markAsRead(index);
      }
    },
    getDetails(index, item) {
      this.setDetails(item);
      this.onClose();
      if (item.readedAt === null) {
        this.readNotification(index, item);
      }
      if (this.$route.path !== '/notifications') {
        this.$router.push('/notifications');
      }
    },
    onClose() {
      this.toogleMenu = false;
      this.forceClose = false;
    },
    displayMenu() {
      setTimeout(() => {
        this.toogleMenu = true;
        this.forceClose = true;
      }, 100);
    },
    middleware() {
      return !this.toogleMenu || this.forceClose;
    },
  },
  watch: {
    listNotifications(newValue) {
      this.setNotifications(newValue);
    },
  },
};
</script>

<style scoped lang="scss">
  @import '~styles/components/_menu-notification.scss';
</style>
